<template>
  <div style="padding:20px">
    <div style="display: flex;align-items: center;margin-bottom: 6px">
      <el-button style="margin-right: 10px" type="primary" @click="openCreateDialog">添加</el-button>
      <el-input
          v-model="query.info"
          placeholder="线索名称、电话"
          style="width: 150px;margin-right: 10px"
      ></el-input>
      <el-select
          v-model="query.followUpId"
          filterable
          remote
          clearable
          reserve-keyword
          placeholder="跟进老师"
          :remote-method="selectCreateCoach"
          :loading="followAndCLoading"
          style="width: 150px;margin-right: 10px"
      >
        <el-option v-for="item in createCoachList" :key="item.coachid" :label="item.coachid+' - '+item.coachname" :value="item.coachid"/>
      </el-select>
      <el-cascader
          v-model="query.followUpDeptIdList"
          placeholder="跟进老师部门"
          :options="deptList"
          collapse-tags
          :collapse-tags-tooltip="true"
          style="width: 180px;margin-right: 10px"
          clearable
          :props="{ multiple: true,value: 'id',label: 'name',children: 'children'}"/>
      <el-select
          v-model="query.createId"
          filterable
          remote
          clearable
          reserve-keyword
          placeholder="创建老师"
          :remote-method="selectFollowCoach"
          :loading="followAndCLoading"
          style="width: 150px;margin-right: 10px"
      >
        <el-option v-for="item in followCoachList" :key="item.coachid" :label="item.coachid+' - '+item.coachname" :value="item.coachid"/>
      </el-select>

      <el-cascader
          v-model="query.createDeptIdList"
          placeholder="创建老师部门"
          :options="deptList"
          collapse-tags
          :collapse-tags-tooltip="true"
          style="width: 180px;margin-right: 10px"
          clearable
          :props="{ multiple: true,value: 'id',label: 'name',children: 'children'}"/>
      <el-select
          clearable
          multiple
          collapse-tags
          collapse-tags-tooltip
          v-model="query.areaIdList"
          placeholder="所属城市"
          style="width: 160px;margin-right: 10px"
      >
        <el-option
            v-for="(item) in areaList"
            :key="item.id" :label="item.name" :value="item.id"
        ></el-option>
      </el-select>

      <el-button :loading="threadLoading" icon="Search" type="primary" @click="getPageList">搜索</el-button>
      <el-button icon="Upload" type="primary" @click="importDialog = true">导入</el-button>
      <el-button icon="Download" type="primary" @click="exportData">导出</el-button>
      <div style="background-color:  #e9e9eb;margin: 0px 10px;padding: 0 6px;border-radius: 10px">
        <el-tooltip
            class="box-item"
            effect="dark"
            :content="transferType == true ? '点击切换为“批量转交数据”！':'点击切换为“批量分配数据”！'"
            placement="top"
        >
          <el-switch
              @click="cancel"
              v-model="transferType"
              class="ml-2"
              width="60"
              size="large"
              inline-prompt
              style="--el-switch-on-color: #409EFF; --el-switch-off-color: #13ce66"
              active-text="分配"
              inactive-text="转交"
          />
        </el-tooltip>
        <el-button style="margin-left: 10px" type="primary" @click="openTransferDialog">批量{{transferType ==true ? '分配':'转交'}}</el-button>
      </div>
    </div>
    <div style="margin-bottom: 20px;display: flex;align-items: center">
      <el-select
          clearable
          multiple
          collapse-tags
          collapse-tags-tooltip
          v-model="query.sourceList"
          placeholder="线索来源"
          style="width: 160px;margin-right: 10px"
      >
        <el-option
            v-for="(item,index) in sourceList"
            :key="index"
            :label="item.dictLabel"
            :value="item.dictValue"
        ></el-option>
      </el-select>


      <el-select
          clearable
          multiple
          collapse-tags
          collapse-tags-tooltip
          v-model="query.stateList"
          placeholder="线索状态"
          style="width: 160px;margin-right: 10px"
      >
        <el-option
            v-for="(item,index) in stateList"
            :key="index"
            :label="item.dictLabel"
            :value="item.dictValue"
        ></el-option>
      </el-select>
      <el-select
          clearable
          multiple
          collapse-tags
          collapse-tags-tooltip
          v-model="query.custTypeIdList"
          placeholder="意向度"
          style="width: 160px;margin-right: 10px"
      >
        <el-option
            v-for="(item,index) in custTypeList"
            :key="index"
            :label="item.dictLabel"
            :value="item.dictValue"
        ></el-option>
      </el-select>
      <div style="margin-right: 10px">
        <el-date-picker
            style="width: 220px"
            v-model="queryCreateDate"
            type="daterange"
            range-separator="至"
            start-placeholder="创建时间"
            end-placeholder="创建时间"
        />
      </div>
      <div style="margin-right: 10px">
        <el-date-picker
            style="width: 220px"
            v-model="queryEnterDate"
            type="daterange"
            range-separator="至"
            start-placeholder="入库时间"
            end-placeholder="入库时间"
        />
      </div>
      <div style="margin-right: 10px">
        <el-date-picker
            style="width: 220px"
            v-model="queryOrderDate"
            type="daterange"
            range-separator="至"
            start-placeholder="成单时间"
            end-placeholder="成单时间"
        />
      </div>
    </div>
    <div>
      <el-table v-loading="threadLoading" :data="threadList" height="580px" @selection-change="choiceItem" row-key="id" ref="multipleTable">
        <el-table-column type="selection" width="30" :selectable="selectable" :reserve-selection="true"/>
        <el-table-column
            show-overflow-tooltip="true"
            prop="id"
            label="ID"
            width="100"
            sortable
        />
        <el-table-column
            show-overflow-tooltip="true"
            prop="name"
            label="姓名"
        />
        <el-table-column
            show-overflow-tooltip="true"
            prop="phone"
            label="电话"
        />
        <el-table-column
            show-overflow-tooltip="true"
            sortable
            label="城市"
        >
          <template #default="scope">
            <span>{{ scope.row.areaName }}-{{ scope.row.flagName }}</span>
          </template>
        </el-table-column>
        <el-table-column
            show-overflow-tooltip="true"
            prop="custTypeName"
            sortable
            label="意向度"
        />
        <el-table-column
            prop="deptName"
            sortable
            label="部门"
        />
        <el-table-column
            show-overflow-tooltip="true"
            prop="followUpName"
            sortable
            label="跟进老师"
            width="120"
        />
        <el-table-column
            show-overflow-tooltip="true"
            prop="sourceName"
            sortable
            label="来源"
        />
        <el-table-column
            show-overflow-tooltip="true"
            prop="stateName"
            sortable
            label="状态"
        />
        <el-table-column
            show-overflow-tooltip="true"
            prop="createTime"
            label="创建时间"
            sortable
            width="160"
        />
        <el-table-column
            show-overflow-tooltip="true"
            prop="createName"
            sortable
            label="创建老师"
            width="120"
        />
        <el-table-column label="操作" width="120" fixed="right" align="center">
          <template #default="scope">
            <el-button style="font-size: 12px" @click="openDetailsDialog(scope.row)" type="text">详情</el-button>
            <el-button style="font-size: 12px" @click="updateItem(scope.row)" type="text">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="display: flex;justify-content: end;margin-top: 20px">
        <el-pagination
            v-model:current-page="query.pageIndex"
            v-model:page-size="query.pageSize"
            :page-sizes="[20, 50, 100, 150, 200, 250, 300]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageTotal"
            @size-change="getPageList"
            @current-change="getPageList"
        />
      </div>
    </div>
    <create-thread ref="createThread" @succeed="getPageList()"/>
    <update-thread ref="updateThread" @succeed="getPageList()"/>
    <thread-details ref="threadDetails"/>
    <transfer-data ref="TransferData" @succeed="cancel(true)"/>
    <el-dialog
        v-model="importDialog"
        width="500px"
        :append-to-body="true"
        :draggable="true"
    >
      <div style="display: flex; justify-content: center">
        <el-upload
            drag
            :limit="1"
            :auto-upload="false"
            :on-change="fileChange"
            :on-exceed="exceedFile"
            :file-list="fileList"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
      </div>
      <div v-for="item in repetitionData" :key="item">
        {{item}}
      </div>
      <div style="display: flex; justify-content: center; padding-top: 30px">
        <el-button type="primary" icon="Upload" :loading="importLoading" @click="importCrmCustomers">开始导入</el-button>
      </div>
      <div>
        <a href="https://codeimg.yanhuotiyu.com/activity/MultiFacePhotos/%E5%AE%A2%E6%88%B7%E7%BA%BF%E7%B4%A2%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx">模板下载</a>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {selectAreaByExpress} from "@/api/area";
import {getCrmContactPageList,transferGetCoach,importCrmCustomers,exportData} from '@/api/partner.js'
import {listCoach} from "@/api/framework";
import {selectDictByType} from '@/api/dict.js'
import CreateThread from "./component/CreateThread";
import ThreadDetails from "./component/ThreadDetails";
import TransferData from "./component/TransferData";
import UpdateThread from "./component/UpdateThread";
import {getListTree} from "@/api/dept";
export default {
  components: {CreateThread,ThreadDetails,TransferData,UpdateThread},
  name:"threadList",
  data() {
    return {
      fileList:[],
      importDialog:false,
      importLoading:false,
      transferType:"分配",
      threadDialog: false,
      threadLoading: false,
      choiceList:[],
      queryCreateDate:[],
      queryEnterDate:[],
      queryOrderDate:[],
      repetitionData:[],
      createCoachList:[],
      followCoachList:[],
      followAndCLoading:false,
      query:{
        areaIdList : [],//城市
        contactType: 3,
        custTypeIdList: [],//客户类型（成熟度）id
        followUpId: null,//跟进人ID
        info: "",//联系人（拼音）
        orderDay: null,//多少天未成单
        pageIndex: 1,
        pageSize: 20,
        followUpDeptId:[],
        followUpDeptIdList:[],
        createDeptId:[],
        createDeptIdList:[],
        createId:"",
        relationDay: null,//多少天未联系
        sourceList: [],//来源
        stateList: [],//客户状态
        trialClassDay: null//多少天未试课
      },
      deptList:[],
      threadList:[],
      pageTotal:0,
      coachList:[],
      areaList:[],//所属城市
      stateList:[],
      sourceList:[],//客户来源
      custTypeList:[],//客户类型
    }
  },
  methods: {
    // 文件状态改变时的钩子
    fileChange(file) {
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      let size = file.size / 1024 / 1024;
      if (extension !== "xlsx") {
        this.fileList = [];
        this.$message.warning("只能上传后缀是.xlsx的文件");
      }
      if (size > 10) {
        this.fileList = [];
        this.$message.warning("文件大小不得超过10M");
      }
      if (extension == "xlsx" && size < 10) {
        this.fileList.push(file.raw);
      }
    },
    exceedFile(files, fileList) {
      this.$message.warning(`只能选择1个文件，当前共选择了 ${files.length + fileList.length} 个`);
    },
    importCrmCustomers() {
      this.importLoading = true;
      if (this.fileList.length === 0) {
        this.$message.warning('请上传文件');
      } else {
        let form = new FormData();
        form.append("file", this.fileList[0]);
        importCrmCustomers(form).then(val => {
          this.importLoading = false;
          if (val.code == 200) {
            this.repetitionData = val.data;
            this.$message.success("导入成功！");
          } else {
            this.$message.error("导入失败！");
          }
        })
      }
    },
    updateItem(item){
      this.$refs.updateThread.openDialog(item);
    },
    selectCreateCoach(coachName){
      if (coachName!=null && coachName!=""){
        this.followAndCLoading = true;
        transferGetCoach(coachName).then(val=>{
          this.followAndCLoading = false;
          if(val.code ==200){
            this.createCoachList = val.data;
          }
        })
      }
    },
    selectFollowCoach(coachName){
      if (coachName!=null && coachName!=""){
        this.followAndCLoading = true;
        transferGetCoach(coachName).then(val=>{
          this.followAndCLoading = false;
          if(val.code ==200){
            this.followCoachList = val.data;
          }
        })
      }
    },
    selectable(row) {
      if (this.transferType === true){
        if (row.isAllocation == 1){
          return true
        }else {
          return false
        }
      }
      if (this.transferType === false){
        if (row.isCareOf == 1){
          return true
        }else {
          return false
        }
      }
    },
    /**
     * 分配和转交状态切换时，清空已选项
     */
    cancel(query) {
      if (query){
        this.getPageList();
      }
      this.$refs.multipleTable.clearSelection();
    },
    /**
     * el-table选择事件
     */
    choiceItem(val){
      this.choiceList = val;
    },
    /**
     * 打开创建线索的弹出层
     */
    openCreateDialog(){
      this.$refs.createThread.openDialog();
    },
    /**
     * 打开线索详情的弹出层
     */
    openDetailsDialog(item){
      this.$refs.threadDetails.openDialog(item);
    },
    /**
     * 开始分配、转交的弹出层
     */
    openTransferDialog(){
      if (this.choiceList.length>0){
        this.$refs.TransferData.openDialog(this.choiceList,this.transferType);
      }else {
        this.$message.success("请先勾选表格数据！");
      }
    },
    //获取列表
    getPageList(){
      this.queryDataDispose();
      this.threadLoading = true;
      getCrmContactPageList(this.query).then(val => {
        this.threadLoading = false;
        if (val.code == 200) {
          this.threadList = val.data.records;
          this.pageTotal = val.data.total;
        } else {
          this.$message.error("获取表格数据失败");
        }
      })
    },
    queryDataDispose(){
      if (this.queryCreateDate!=null && this.queryCreateDate.length>0){
        this.query.startCreateTime = this.dateFormat(this.queryCreateDate[0]);
        this.query.endCreateTime = this.dateFormat(this.queryCreateDate[1]);
      }else {
        this.query.startCreateTime = null;
        this.query.endCreateTime = null;
      }
      if (this.queryEnterDate!=null && this.queryEnterDate.length>0){
        this.query.startWarehouseEntryTime = this.dateFormat(this.queryEnterDate[0]);
        this.query.endWarehouseEntryTime = this.dateFormat(this.queryEnterDate[1]);
      }else {
        this.query.startWarehouseEntryTime = null;
        this.query.endWarehouseEntryTime = null;
      }
      if (this.queryOrderDate!=null && this.queryOrderDate.length>0){
        this.query.startOrderTime = this.dateFormat(this.queryOrderDate[0]);
        this.query.endOrderTime = this.dateFormat(this.queryOrderDate[1]);
      }else {
        this.query.startOrderTime = null;
        this.query.endOrderTime = null;
      }
      if (this.query.followUpDeptIdList?.length>0){
        let list = [];
        this.query.followUpDeptIdList.forEach((item) => {
          list.push(item[item.length - 1])
        })
        this.query.followUpDeptId = list;
      }else {
        this.query.followUpDeptId = [];
      }
      if (this.query.createDeptIdList?.length>0){
        let list = [];
        this.query.createDeptIdList.forEach((item) => {
          list.push(item[item.length - 1])
        })
        this.query.createDeptId = list;
      }else {
        this.query.createDeptId = [];
      }
    },
    exportData(){
      this.queryDataDispose();
      exportData(this.query);
      this.$message.success("下载成功后将在右上角下载工具里显示！");
    },
    getTypeList(commodityType) {
      if (commodityType!=null){
        commodityType.forEach(items => {
          if (items!=null){
            if (items.children!=null && items.children.length > 0) {
              this.getTypeList(items.children);
            } else {
              items.children = undefined;
            }
          }
        });
        return commodityType;
      }
    },
    //检索 教练的姓名
    selectCoach(e) {
      listCoach(e).then(res => {
        if (res.code === 200) {
          this.coachList = res.data
        } else {
          this.$message.error('')
        }
      })
    },
    dateFormat(val) {
      var date = new Date(val);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      const time = parseInt(y) + "-" + m + "-" + d;
      return time;
    },
  },
  created() {
    this.getPageList();
    selectDictByType('crmSource').then(res=>{
      if(res.code ==200){
        this.sourceList = res.data;
      }
    })
    selectDictByType('custType').then(res=>{
      if(res.code == 200){
        this.custTypeList = res.data;
      }
    })
    selectDictByType('custThisState').then(res=>{
      if(res.code == 200){
        this.stateList = res.data;
      }
    })
    getListTree().then(res => {
      this.deptList = this.getTypeList(res.data);
    })
    selectAreaByExpress().then(res => {
      if (res.code == 200) {
        this.areaList = res.data
      } else {
        this.$message.error("获取城市数据失败");
      }
    })
    // selectArea().then(res=>{
    //   this.areaList = res.data;
    // })
  },
}
</script>
<style>


</style>