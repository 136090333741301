<template>
  <div>
    <el-form :rules="recordRules" ref="recordRules" :model="record">
      <el-row>
        <el-col :span="7">
          <el-form-item label="联系客户" prop="crmCustRelationId">
            <el-select v-model="record.crmCustRelationId" placeholder="请选择" style="width: 180px;">
              <el-option v-for="item in contacts" :value="item.id" :key="item.id" :label="item.name"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="联系时间" prop="relationTime">
            <el-date-picker style="width: 180px;"
                v-model="record.relationTime"
                type="datetime"
                placeholder="请选择"
            />
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="意向程度" prop="custTypeId">
            <el-select  v-model="record.custTypeId" style="width: 180px;">
              <el-option
                  v-for="(item,index) in custTypeList"
                  :key="index"
                  :label="item.dictLabel"
                  :value="item.dictValue"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <div style="background-color: #F5F7FA;margin:10px 0 0 0px;padding: 10px 14px;border-radius: 10px">
            <div style="margin-bottom: 20px;font-weight: bold">沟通内容</div>
            <el-input
                v-model="record.relationMatter"
                :rows="3"
                type="textarea"
                placeholder="请输入"
            />
          </div>
        </el-col>
      </el-row>
<!--      <el-row>-->
<!--        <el-col :span="24">-->
<!--          <div style="background-color: #F5F7FA;margin:10px 0 0 0px;padding: 10px 14px;border-radius: 10px">-->
<!--            <div style="margin-bottom: 20px;font-weight: bold">沟通图片</div>-->
<!--            <el-upload-->
<!--                ref='upload'-->
<!--                class="upload"-->
<!--                accept="image/jpg,image/jpeg,image/png"-->
<!--                :http-request="uploadCardProduct"-->
<!--                :on-remove="(file, fileList) => {handleRemovePro(file, fileList)}"-->
<!--                :limit="9"-->
<!--                list-type="picture-card">-->
<!--             <el-button icon="Plus" text />-->
<!--            </el-upload>-->
<!--          </div>-->
<!--        </el-col>-->
<!--      </el-row>-->
    </el-form>
  </div>
</template>

<script>
import {addRecord,getContactsById} from '@/api/partner.js'
import {selectDictByType} from '@/api/dict.js'
export default {
  name: "CreateCommunication",
  props:{
    thread:{
      type:Object,
    }
  },
  data() {
    return {
      contacts:[],
      record:{},
      recordRules:{
        crmCustRelationId: [{required: true, message: '请选择！', trigger: 'change'},],
        relationTime: [{required: true,message: '请选择！',trigger: 'change'}],
        custTypeId: [{required: true,message: '请选择！',trigger: 'change'}],
      },
    }
  },
  methods:{
    confirm(id){
      this.record.crmCustId = id;
      this.$refs['recordRules'].validate((valid) => {
        if (valid){
          this.record.relationTime = this.dateFormat(this.record.relationTime);
          addRecord(this.record).then(val=>{
            Object.keys(this.record).forEach((key) => (this.record[key] = ''));
            if (val.code == 200) {
              this.$emit('succeed');
              this.$message.success("添加成功！");
            } else {
              this.$message.error(val.message);
            }
          })
        }
      })
    },
    /**
     * 查询联系人
     */
    getContactsById(){
      getContactsById(this.thread.id).then(val=>{
        if(val.code ==200){
          this.contacts = val.data;
        }
      })
    },
    dateFormat(val) {
      var myDate = new Date(val);
      var year = myDate.getFullYear(); //年
      var month = myDate.getMonth() + 1; //月
      var day = myDate.getDate(); //日
      var hh = myDate.getHours(); //时
      var mm = myDate.getMinutes();
      var ss = myDate.getSeconds();
      return year +"-" +month +"-" + day +" " + hh +":" +mm +":" +ss;
    },

  },
  created() {
    this.getContactsById();
    selectDictByType('custType').then(res=>{
      if(res.code == 200){
        this.custTypeList = res.data;
      }
    })
  }
}
</script>

<style scoped>
/deep/ .el-upload--picture-card {
  width: 80px;
  height: 80px;
}

/deep/ .el-upload {
  width: 80px;
  height: 80px;
  line-height: 80px;
}

/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 80px;
  height: 80px;
  line-height: 80px;
}

/deep/ .el-upload-list--picture-card .el-upload-list__item-thumbnail {
  width: 80px;
  height: 80px;
  line-height: 80px;
}
</style>