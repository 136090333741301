<template>
  <div>
    <el-dialog title="线索详情" v-model="detailsDialog" width="1200px" draggable>
      <el-tabs v-model="activeName" class="demo-tabs" >
        <el-tab-pane style="height: 460px" label="线索详情" name="details">
          <el-descriptions align="left" column="3">
            <el-descriptions-item label="名称：">{{item.name}}</el-descriptions-item>
            <el-descriptions-item label="电话：">{{item.phone}}</el-descriptions-item>
            <el-descriptions-item label="城市：">{{item.areaName}} - {{item.flagName}}</el-descriptions-item>
            <el-descriptions-item></el-descriptions-item>
            <el-descriptions-item></el-descriptions-item>
          </el-descriptions>
          <el-descriptions column="5">
            <el-descriptions-item label="类型：">{{item.custTypeName}}</el-descriptions-item>
            <el-descriptions-item label="状态：">
              <el-tag size="small">{{item.stateName}}</el-tag>
            </el-descriptions-item>
            <el-descriptions-item label="来源：">
              <el-tag size="small">{{item.sourceName}}</el-tag>
            </el-descriptions-item>
            <el-descriptions-item label="跟进老师：">
              <el-tag size="small">{{item.deptName}} - {{item.followUpName}}</el-tag>
            </el-descriptions-item>
            <el-descriptions-item label="创建老师：">
              <el-tag size="small">{{item.createName}}</el-tag>
            </el-descriptions-item>
          </el-descriptions>
          <el-descriptions column="4">
            <el-descriptions-item label="最近成单时间：">{{item.isEndOrderTime}}</el-descriptions-item>
            <el-descriptions-item label="最近联系时间：">{{item.isEndIntentionTime}}</el-descriptions-item>
            <el-descriptions-item label="最近试课时间：">{{item.lastTrialClassTime}}</el-descriptions-item>
            <el-descriptions-item label="创建时间：">{{item.createTime}}</el-descriptions-item>
          </el-descriptions>
          <el-descriptions column="3">
            <el-descriptions-item label="最后成单id：">
              <el-tag size="small">{{item.ordersId}}</el-tag>
            </el-descriptions-item>
          </el-descriptions>
        </el-tab-pane>
        <el-tab-pane style="height: 460px" label="联系方式" name="second">
          <el-button type="primary" @click="createContactDialog = true" style="margin-bottom: 12px">添加</el-button>
          <el-table :data="contacts" style="width: 100%" height="380px">
            <el-table-column prop="id" label="id" width="180" />
            <el-table-column prop="name" label="姓名"/>
            <el-table-column prop="phone" label="电话"/>
            <el-table-column prop="remark" label="备注"/>
            <el-table-column prop="createTime" label="创建时间"/>
          </el-table>
        </el-tab-pane>
        <el-tab-pane style="height: 460px" label="跟进列表" name="fourth">
          <el-button type="primary" @click="CreateCommunicationDialog = true" style="margin-bottom: 12px">添加</el-button>
          <el-table :data="contacted" height="380px" style="width: 100%">
            <el-table-column width="100" show-overflow-tooltip prop="id" label="id"/>
            <el-table-column width="120" show-overflow-tooltip prop="relationName" label="跟进老师"/>
            <el-table-column width="120" show-overflow-tooltip prop="crmCustRelation.name" label="联系用户"/>
            <el-table-column width="120" show-overflow-tooltip prop="crmCustRelation.phone" label="联系电话"/>
            <el-table-column width="140" show-overflow-tooltip prop="custTypeIdName" label="意向程度"/>
            <el-table-column width="280" show-overflow-tooltip prop="relationMatter" label="联系内容"/>
            <el-table-column width="180" show-overflow-tooltip prop="relationTime" label="跟进时间"/>
            <el-table-column width="180" show-overflow-tooltip prop="createTime" label="创建时间"/>
          </el-table>
        </el-tab-pane>
        <el-tab-pane style="height: 460px" label="学员列表" name="third">
          <el-button type="primary" @click="createStudentDialog = true" style="margin-bottom: 12px">添加</el-button>
          <el-table :data="students" height="380px" style="width: 100%">
            <el-table-column prop="id" label="id" width="180" />
            <el-table-column prop="studentName" label="姓名"/>
            <el-table-column prop="studentPhone" label="性别">
              <template #default="scope">
                <span>{{scope.row.studentSex == 0 ? "女":"男"}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="studentClass" show-overflow-tooltip label="班级"/>
            <el-table-column prop="studentSchool" show-overflow-tooltip label="学校"/>
            <el-table-column prop="studentGrade" label="毕业届数"/>
            <el-table-column prop="createTime" width="180" label="创建时间"/>
            <el-table-column fixed="right" label="操作" width="80">
              <template #default="scope">
                <el-button link type="primary" size="small" @click="setUpdateStudent(scope.row)">编辑</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane style="height: 460px" label="试课申请" name="trial">
          <el-button type="primary" @click="createTrialClassDialog = true" style="margin-bottom: 12px">添加</el-button>
          <el-table :data="trialClassList" height="380px" style="width: 100%">
            <el-table-column prop="id" label="id" width="100" />
            <el-table-column prop="studentRecord.studentName" show-overflow-tooltip label="试课学员"/>
            <el-table-column prop="studentRecord.studentPhone" show-overflow-tooltip label="试课电话"/>
            <el-table-column label="学员性别" width="100">
              <template #default="scope">
                <span>{{scope.row.studentRecord?.studentSex == 0 ? "女":"男"}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="areaName" show-overflow-tooltip label="试课校区"/>
            <el-table-column prop="trialClassTime" width="180" label="预约试课时间"/>
            <el-table-column prop="createName" show-overflow-tooltip label="创建老师"/>
            <el-table-column prop="createTime" width="180" label="创建时间"/>
            <el-table-column fixed="right" label="操作" width="100">
              <template #default="scope">
                <el-popconfirm
                    width="220"
                    @confirm="cancelTrialClass(scope.row)"
                    confirm-button-text="确 定"
                    cancel-button-text="取 消"
                    title="确定取消本次试课吗！"
                >
                  <template #reference>
                    <el-button link type="primary"  size="small">取消申请</el-button>
                  </template>
                </el-popconfirm>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane style="height: 460px" label="成单列表" name="orders">
          <el-table :data="orderList" height="380px" style="width: 100%">
            <el-table-column prop="oid" label="id" width="100" />
            <el-table-column prop="studentName" show-overflow-tooltip label="学员"/>
            <el-table-column prop="studentPhone" show-overflow-tooltip label="电话"/>
            <el-table-column prop="cardAttributeRule.name" show-overflow-tooltip label="课程类型"/>
            <el-table-column label="已用/剩余" width="100">
              <template #default="scope">
                <span>{{scope.row.ouse}}/{{scope.row.remaining}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="money" show-overflow-tooltip label="金额"/>
            <el-table-column prop="state" show-overflow-tooltip label="状态"/>
            <el-table-column prop="serveCoachName" show-overflow-tooltip label="服务老师"/>
            <el-table-column prop="createTime" width="180" label="创建时间"/>
            <el-table-column fixed="right" label="操作" width="80">
              <template #default="scope">
                <el-button link type="primary" size="small" @click="toOrder(scope.row.oid)">详情</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
      <el-dialog draggable
                 v-model="createContactDialog"
                 width="800"
                 title="新增联系人"
                 append-to-body>
        <create-contact v-if="createContactDialog" ref="createContact" @succeed="getContactsById"/>
        <template #footer>
          <div class="dialog-footer">
            <el-button @click="createContactDialog = false">取 消</el-button>
            <el-button type="primary" @click="createContact">确 定</el-button>
          </div>
        </template>
      </el-dialog>
      <el-dialog draggable
                 v-model="createStudentDialog"
                 width="1000"
                 title="新增学员"
                 append-to-body>
        <create-student v-if="createStudentDialog" ref="createStudent" @succeed="getStudentsById"/>
        <template #footer>
          <div class="dialog-footer">
            <el-button @click="createStudentDialog = false">取 消</el-button>
            <el-button type="primary" @click="createStudent">确 定</el-button>
          </div>
        </template>
      </el-dialog>
      <el-dialog draggable
                 v-model="updateStudentDialog"
                 width="1000"
                 title="编辑学员"
                 append-to-body>
        <update-student ref="updateStudent" @succeed="getStudentsById"/>
        <template #footer>
          <div class="dialog-footer">
            <el-button @click="updateStudentDialog = false">取 消</el-button>
            <el-button type="primary" @click="updateStudent">确 定</el-button>
          </div>
        </template>
      </el-dialog>
      <el-dialog draggable
                 v-model="CreateCommunicationDialog"
                 width="1000"
                 title="新增联系记录"
                 append-to-body>
        <create-communication @succeed="contactedRecord" ref="createCommunication" v-if="CreateCommunicationDialog" :thread="item"/>
        <template #footer>
          <div class="dialog-footer">
            <el-button @click="CreateCommunicationDialog = false">取 消</el-button>
            <el-button type="primary" @click="createRecord">确 定</el-button>
          </div>
        </template>
      </el-dialog>
      <el-dialog draggable
                 v-model="createTrialClassDialog"
                 width="1000"
                 title="申请试课"
                 append-to-body>
        <create-trial-class ref="createTrialClass" :students="students" @succeed="selectTrialClassById"/>
        <template #footer>
          <div class="dialog-footer">
            <el-button @click="createTrialClassDialog = false">取 消</el-button>
            <el-button type="primary" @click="createTrialClass">确 定</el-button>
          </div>
        </template>
      </el-dialog>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="detailsDialog = false">取 消</el-button>
          <el-button type="primary">确 定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>

</template>

<script>
import {getContactsById,getStudentsById,contactedRecord,selectTrialClassById,selectOrdersById,deleteTrialClassApply} from '@/api/partner.js'
import CreateStudent from "./CreateStudent";
import UpdateStudent from "./UpdateStudent";
import CreateContact from "./CreateContact";
import CreateCommunication from "./CreateCommunication";
import CreateTrialClass from "./CreateTrialClass";
export default {
  name: "ThreadDetails",
  components: {CreateStudent,CreateContact,CreateCommunication,CreateTrialClass,UpdateStudent},
  data() {
    return {
      detailsDialog:false,
      activeName:'details',
      item:null,
      contacts:[],
      students:[],
      orderList:[],
      trialClassList:[],
      contactedQuery:{
        crmCustId:null,
        "pageIndex": 1,
        "pageSize": 10,
      },
      contacted:[],
      createContactDialog:false,
      createStudentDialog:false,
      updateStudentDialog:false,
      createTrialClassDialog:false,
      CreateCommunicationDialog:false,
    }
  },

  methods:{
    toOrder(id){
      this.$router.push({name: 'OrdersManage',params: {id:id}});
    },
    /**
     * 申请试课
     */
    createTrialClass(){
      this.$refs.createTrialClass.confirm(this.item.id);
    },
    /**
     * 取消申请试课
     */
    cancelTrialClass(val){
      deleteTrialClassApply(val).then(val=>{
        if(val.code ==200){
          this.$message.success("取消成功！");
          this.selectTrialClassById();
        }
      })
    },
    /**
     * 添加学员
     */
    createStudent(){
      this.$refs.createStudent.confirm(this.item.id);
    },
    /**
     * 编辑学员
     */
    updateStudent(){
      this.$refs.updateStudent.confirm(this.item.id);
    },
    /**
     * 设置编辑学员的信息（回显）
     */
    setUpdateStudent(val){
      let that = this;
      this.updateStudentDialog = true;
      setTimeout(function(){
        that.$refs.updateStudent.setUpdateItem(val);
      }, 800);
    },
    /**
     * 添加线索联系人
     */
    createContact(){
      this.$refs.createContact.confirm(this.item.id);
    },
    /**
     * 添加线索跟进记录
     */
    createRecord(){
      this.$refs.createCommunication.confirm(this.item.id);
    },
    openDialog(item){
      this.detailsDialog = true;
      this.item = item;
      this.contactedQuery.crmCustId = item.id;
      this.getContactsById();
      this.getStudentsById();
      this.contactedRecord();
      this.selectTrialClassById();
      this.selectOrdersById();
    },
    /**
     * 查询联系人
     */
    getContactsById(){
      this.createContactDialog = false;
      getContactsById(this.item.id).then(val=>{
        if(val.code ==200){
          this.contacts = val.data;
        }else {
          this.contacts = [];
          this.$message.error(val.message);
        }
      })
    },
    /**
     * 查询学员
     */
    getStudentsById(){
      this.createStudentDialog = false;
      this.updateStudentDialog = false;
      getStudentsById(this.item.id).then(val=>{
        if(val.code ==200){
          this.students = val.data;
        }else {
          this.students = [];
          this.$message.error(val.message);
        }
      })
    },
    /**
     * 查询试课申请
     */
    selectTrialClassById(){
      this.createTrialClassDialog = false;
      selectTrialClassById(this.item.id).then(val=>{
        if(val.code ==200){
          this.trialClassList = val.data;
        }else {
          this.trialClassList = [];
          this.$message.error(val.message);
        }
      })
    },
    /**
     * 查询联系记录
     */
    contactedRecord(){
      this.CreateCommunicationDialog = false;
      contactedRecord(this.contactedQuery).then(val=>{
        if(val.code ==200){
          this.contacted = val.data.records;
        }else {
          this.contacted = [];
          this.$message.error(val.message);
        }
      })
    },
    /**
     * 查询订单列表
     */
    selectOrdersById(){
      selectOrdersById(this.item.id).then(val=>{
        if(val.code ==200){
          this.orderList = val.data;
        }else {
          this.orderList = [];
        }
      })
    }
  },
  created() {

  }
}
</script>

<style scoped>
.contacts{
  background-color: #f5f7fa;
  border-radius: 10px;
  padding: 10px;
}
.contacts-item{
  background-color: white;
  margin: 10px 10px 0 10px;
  padding: 10px;
  border-radius: 10px
}
.remark{
  background-color: #f5f7fa;
  padding: 10px;
  border-radius: 10px;
  height: 100px;
}
.add{
  border: 1px dashed #A8ABB2;
  height: 40px;
  margin:50px 180px 20px 180px;
  border-radius: 10px;
  text-align: center;
  line-height: 30px;

}

</style>