import request from '../utils/request';
/**
 * 添加线索
 */
export const addThread = (item) => {
    return request({
        url: '/sys/crmCustomers/add',
        method: 'POST',
        data: item
    });
};
/**
 * 添加线索
 */
export const updateThread = (item) => {
    return request({
        url: '/sys/crmCustomers/updateCrmCustomers',
        method: 'POST',
        data: item
    });
};
/**
 * 添加线索联系用户
 */
export const addContact = (item) => {
    return request({
        url: '/sys/crmCustRelation/addCrmCustRelation',
        method: 'POST',
        data: item
    });
};
/**
 * 添加学员
 */
export const addStudent = (item) => {
    return request({
        url: '/sys/crmCustomers/addStudentRecord',
        method: 'POST',
        data: item
    });
};
/**
 * 添加线索跟进记录
 */
export const addRecord = (item) => {
    return request({
        url: '/sys/crmCustomers/addCrmContact',
        method: 'POST',
        data: item
    });
};

/**
 * 获取线索列表
 */
export const getCrmContactPageList = (query) => {
    return request({
        url: '/sys/crmCustomers/getPageList',
        method: 'POST',
        data: query,

    });
};
/**
 * 获取线索列表
 */
export const contactedRecord = (query) => {
    return request({
        url: '/sys/crmCustomers/getCrmContactPageList',
        method: 'POST',
        data: query,

    });
};

/**
 * 申请试课
 */
export const addTrialClassApply = (item) => {
    return request({
        url: '/sys/crmCustomers/addTrialClassApply',
        method: 'POST',
        data: item,

    });
};

/**
 * 取消申请试课
 */
export const deleteTrialClassApply = (item) => {
    return request({
        url: '/sys/crmCustomers/deleteTrialClassApply',
        method: 'POST',
        data: item,
    });
};
/**
 * 根据线索id 查询联系人列表
 */
export const getContactsById = (crmCustId) => {
    return request({
        url: '/sys/crmCustomers/selectRelationListByCrmCustId',
        method: 'get',
        params: {crmCustId}
    });
};

/**
 * 根据线索id 查询学员列表
 */
export const getStudentsById = (crmCustId) => {
    return request({
        url: '/sys/crmCustomers/getStudentRecordList',
        method: 'get',
        params: {crmCustId}
    });
};

/**
 * 根据线索id 查询试课信息
 */
export const selectTrialClassById = (crmCustId) => {
    return request({
        url: '/sys/crmCustomers/selectTrialClassApplyByCustId',
        method: 'get',
        params: {crmCustId}
    });
};


/**
 * 根据线索id 查询订单列表
 */
export const selectOrdersById = (crmCustId) => {
    return request({
        url: '/sys/crmCustomers/ordersListByCrmCustId',
        method: 'get',
        params: {crmCustId}
    });
};

export const selectArea = () => {
    return request({
        url: '/sys/area/selectArea',
        method: 'get',
    });
};

/**
 * 分配时查询老师的接口
 */
export const allocationGetCoach = (keyword) => {
    return request({
        url: '/sys/coach/listCoach',
        method: 'get',
        params: {keyword}
    });
};
/**
 * 转交时查询老师的接口
 */
export const transferGetCoach = (keyword) => {
    return request({
        url: '/sys/coach/getListCoachAll',
        method: 'get',
        params: {keyword}
    });
};

/**
 * 批量分配或者转交
 */
export const allocationOrTransfer = (item) => {
    return request({
        url: '/sys/crmCustomers/allocation',
        method: 'POST',
        data: item,
    });
};

/**
 * 批量分配或者转交
 */
export const updateStudent = (item) => {
    return request({
        url: '/sys/studentRecord/updateStudentByCoach',
        method: 'POST',
        data: item,
    });
};

/**
 * 退费导入
 * @param query
 * @returns {AxiosPromise}
 */
export const importCrmCustomers = (file) => {
    return request({
        url: '/sys/crmCustomers/importCrmCustomers',
        headers: {
            "Content-type": "multipart/form-data",
        },
        method: 'post',
        data: file
    });
};

/**
 * 导出
 * @param query
 * @returns {*}
 */
export function exportData(query) {
    return request({
        url: '/sys/crmCustomers/exportCrmCustomers',
        method: 'post',
        data: query,
        responseType: 'arraybuffer'
    })
}
