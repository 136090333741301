import request from '../utils/request'

//获取分页
export const page = (query) => {
    return request({
        url: '/sys/dept/page',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(query),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};

//获取负责人树形结构（dept 编辑用户的时候也有遇到这个接口）
export const getListTree = () => {
    return request({
        url: '/sys/dept/listtree',
        method: 'get',
        params: {},
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};

//添加
export const add = (query) => {
    return request({
        url: '/sys/dept/insert',
        method: 'post',
        dataType: "json",
        data: JSON.stringify(query),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};
//修改
export const edit = (query) => {
    return request({
        url: '/sys/dept/edit',
        method: 'post',
        dataType: "json",
        data: JSON.stringify(query),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};
//修改当行状态
export const updateState = (id,state) => {
    return request({
        url: '/sys/dept/updateState',
        method: 'get',
        params: {id,state},
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};
//组织架构 修改
export const editDept = (query) => {
    return request({
        url: '/sys/dept/editDept',
        method: 'post',
        dataType: "json",
        data: JSON.stringify(query),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};
//根据教练姓名 检索自己可视区域地区内的教练 
export const listCoach = (keyword) => {
    return request({
        url: '/sys/coach/listCoach',
        method: 'get',
        params: {keyword},
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};

