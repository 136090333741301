<template>
<div>
  <el-form :rules="studentRules" ref="addRules" :model="student">
    <el-row>
      <el-col :span="6">
        <el-form-item label="头像">
          <el-upload
              ref='upload'
              class="upload"
              accept="image/jpg,image/jpeg,image/png"
              :http-request="uploadCardProduct"
              :limit="1"
              list-type="picture-card">
            <el-icon><Plus /></el-icon>
          </el-upload>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="6">
        <el-form-item label="姓名" prop="studentName">
          <el-input v-model="student.studentName" style="width: 150px;"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="电话" prop="studentPhone">
          <el-input v-model="student.studentPhone" style="width: 150px;" maxlength="11"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="性别" prop="studentSex">
          <el-select  v-model="student.studentSex" style="width: 150px;">
            <el-option key="0" label="女" value="0"></el-option>
            <el-option key="1" label="男" value="1"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="6">
        <el-form-item label="生日" prop="birthday">
          <el-date-picker
              v-model="student.birthday"
              type="date" style="width: 150px;"
              placeholder="请选择"
          />
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="年级" prop="studentSex" >
          <el-select v-model="student.studentGrade" placeholder="请选择" style="width: 150px;">
            <el-option v-for="item in garde" :value="item.dictValue" :key="item.id" :label="item.dictLabel"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="学校" prop="studentSchool" >
          <el-input  v-model="student.studentSchool" style="width: 150px;"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="班级" >
          <el-input  v-model="student.studentClass" style="width: 150px;"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>

</div>
</template>

<script>
import {addStudent} from '@/api/partner.js'
import {selectDictByType} from '@/api/dict.js'
import {uploadImage} from '@/api/helpManual'
export default {
  name: "CreateStudent",
  data() {
    return {
      student:{
      },
      studentRules:{
        studentName: [{required: true, message: '请输入学员姓名！', trigger: 'change'},],
        studentSex: [{required: true,message: '请选择性别！',trigger: 'change'}],
        birthday: [{required: true,message: '请选择生日！',trigger: 'change'}],
        studentGrade: [{required: true,message: '请选择年级！',trigger: 'change'}],
        studentPhone: [
          {required: true,message: '请输入联系电话！',trigger: 'change'},
          {pattern: /^1[3-9]\d{9}$/, message: "请输入正确的手机号码", trigger: "blur",}],
      },
      garde:[],
    }
  },
  methods:{
    confirm(id){
      this.student.crmCustId = id;
      this.$refs['addRules'].validate((valid) => {
        if (valid){
          this.student.birthday = this.dateFormat(this.student.birthday);
          addStudent(this.student).then(val=>{
            Object.keys(this.student).forEach((key) => (this.student[key] = ''));
            if (val.code == 200) {
              this.$emit('succeed');
              this.$message.success("添加成功");
            } else {
              this.$message.error(val.message);
            }
          })
        }
      })
    },
    async uploadCardProduct(file) {
      const fileName = file.file.name;
      const lastIndex = fileName.lastIndexOf(".");
      if (lastIndex !== -1) {
        const extension = fileName.substring(lastIndex + 1);
        let a = ['png', 'jpg', 'jpeg','PNG', 'JPG', 'JPEG'];
        if (a.includes(extension)) {
          await this.uploadImg(file,fileName)
        }else {
          this.$message.warning('图片格式有误！');
        }
      }
    },
    async uploadImg(file) {
      let url = null
      const data = new FormData()
      data.append('multipartFile', file.file)
      data.append('flag', 'card')
      data.append('fileName', file.file.name)
      await uploadImage(data).then(res => {
        if (res.code == 200) {
          this.student.facePortrait = res.data;
        } else {
          this.$message.warning('图片上传失败');
        }
      })
      return url
    },
    dateFormat(val) {
      var date = new Date(val);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      const time = parseInt(y) + "-" + m + "-" + d;
      return time;
    },
  },
  created() {
    selectDictByType('garde').then(res=>{
      if(res.code == 200){
        this.garde = res.data;
      }
    })
  }
}
</script>

<style scoped>
/deep/ .el-upload--picture-card {
  width: 80px;
  height: 80px;
}

/deep/ .el-upload {
  width: 80px;
  height: 80px;
  line-height: 80px;
}

/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 80px;
  height: 80px;
  line-height: 80px;
}

/deep/ .el-upload-list--picture-card .el-upload-list__item-thumbnail {
  width: 80px;
  height: 80px;
  line-height: 80px;
}
</style>