<template>
  <div style="height: 300px;">
    <el-form :rules="formRules" ref="formRules" :model="form">
      <el-row>
        <el-col :span="7">
          <el-form-item label="试课学员" prop="studentId" label-width="100px">
            <el-select
                v-model="form.studentId"
                class="m-2"
                placeholder="请选择"
                style="width: 240px"
            >
              <el-option
                  v-for="item in students" :key="item.id" :value="item.id"
                  :label="item.studentName+' - '+item.studentPhone"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="试课时间" prop="trialClassTime" label-width="100px">
            <el-date-picker
                v-model="form.trialClassTime"
                type="datetime"
                placeholder="请选择"
            />
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="试课校区" prop="areaId" label-width="100px">
            <el-cascader
                filterable
                :options="campusList"
                :show-all-levels="false"
                v-model="form.areaId"
                :props="{value: 'id',label: 'name'}"
            >
              <template #default="{ node , data }">
                <span>{{data.name}}</span>
                <span v-if="data.flag == 1"> - 中招</span>
                <span v-if="data.flag == 2"> - 贝体</span>
                <span v-if="data.flag == 4"> - 高考</span>
                <span v-if="!node.isLeaf"></span>
              </template>
            </el-cascader>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import {addTrialClassApply} from '@/api/partner.js'
import { listCampusTree } from "@/api/area.js";
export default {
  name: "CreateTrialClass",
  props: {
    students: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data() {
    return {
      form:{
        areaId:null,
        studentId:null,
        trialClassTime:null
      },
      campusList:[],
      formRules:{
        studentId: [{required: true, message: '请选择！', trigger: 'change'},],
        trialClassTime: [{required: true, message: '请选择！', trigger: 'change'},],
        areaId: [{required: true, message: '请选择！', trigger: 'change'},],
      }
    }
  },
  methods: {
    confirm(id){
      this.form.crmCustId = id;
      if (this.form.areaId!=null && this.form.areaId.length>0){
        this.form.areaId = this.form.areaId[this.form.areaId.length-1]
      } else {
        this.form.areaId = null;
      }
      this.form.trialClassTime = this.form.trialClassTime == null ? null : this.dateFormat(this.form.trialClassTime);
      this.$refs['formRules'].validate((valid) => {
        if (valid){
          addTrialClassApply(this.form).then(val=>{
            Object.keys(this.form).forEach((key) => (this.form[key] = ''));
            if (val.code == 200) {
              this.$emit('succeed');
              this.$message.success("申请成功！");
            } else {
              this.$message.error(val.message);
            }
          })
        }
      })
    },
    dateFormat(val) {
      var myDate = new Date(val);
      var year = myDate.getFullYear(); //年
      var month = myDate.getMonth() + 1; //月
      var day = myDate.getDate(); //日
      var hh = myDate.getHours(); //时
      var mm = myDate.getMinutes();
      var ss = myDate.getSeconds();
      return year +"-" +month +"-" + day +" " + hh +":" +mm +":" +ss;
    },
  },
  created() {
    listCampusTree().then((val) => {
      if (val.code == 200) {
        this.campusList = val.data;
      }
    });
  }
}
</script>

<style scoped>

</style>