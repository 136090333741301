<template>
  <div>
    <el-form :rules="contactRules" ref="contactRules" :model="contact">
      <el-row>
        <el-col :span="9">
          <el-form-item label="客户姓名" prop="name" label-width="100px">
            <el-input  v-model="contact.name" ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="9">
          <el-form-item label="客户电话" prop="phone" label-width="100px">
            <el-input v-model="contact.phone" maxlength="11"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import {addContact} from '@/api/partner.js'
export default {
  name: "CreateContact",
  data() {
    return {
      contact:{
        relationType:2,
      },
      contactRules:{
        name: [{required: true, message: '请输入！', trigger: 'change'},],
        phone: [
          {required: true,message: '请输入联系电话！',trigger: 'change'},
          {pattern: /^1[3-9]\d{9}$/, message: "请输入正确的手机号码", trigger: "blur",}],
      }
    }
  },
  methods:{
    confirm(id){
      this.contact.crmCustId = id;
      this.$refs['contactRules'].validate((valid) => {
        if (valid){
          addContact(this.contact).then(val=>{
            Object.keys(this.contact).forEach((key) => (this.contact[key] = ''));
            if (val.code == 200) {
              this.$emit('succeed');
              this.$message.success("添加成功！");
            } else {
              this.$message.error(val.message);
            }
          })
        }
      })
    },
  }

}
</script>

<style scoped>

</style>