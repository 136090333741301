<template>
  <el-dialog title="添加线索" v-model="createDialog" width="1100px">
    <el-dialog
        v-model="losingShowDialog"
        width="500"
        title="新增线索失败"
        append-to-body
    >
      <div>
        <div>线索名称：{{losingShow.name}}</div>
        <div>线索名称：{{losingShow.phone}}</div>
        <div>线索状态：{{losingShow.stateName}}</div>
        <div>线索来源：{{losingShow.sourceName}}</div>
        <div>所属城市：{{losingShow.areaName}} - {{losingShow.flagName}}</div>
        <div>所属部门：{{losingShow.deptName}}</div>
        <div>跟进老师：{{losingShow.followUpName}}</div>
        <div>创建老师：{{losingShow.createName}}</div>
        <div>创建时间：{{losingShow.createTime}}</div>

        <div style="text-align: center;color: red;font-size: 46px;font-weight: 900;margin-top: 50px;">该用户添加过了！</div>
      </div>
    </el-dialog>
    <div style="padding-right: 30px;height: 300px">
      <el-form :rules="addRules" ref="addRules" :model="addFrom">
        <el-row>
          <el-col :span="6">
            <el-form-item label="客户姓名" prop="name" label-width="100px">
              <el-input  v-model="addFrom.name" ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="客户电话" prop="phone" label-width="100px">
              <el-input v-model="addFrom.phone" maxlength="11"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="所属城市" prop="areaId" label-width="100px">
              <el-select v-model="addFrom.areaId">
                <el-option v-for="(item) in areaList" :label="item.name" :value="item.id" :key="item.id" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="城市类型" prop="flag" label-width="100px">
              <el-select v-model="addFrom.flag" placeholder="请选择类型" clearable>
                <el-option label="未知" :value="0"></el-option>
                <el-option label="中招" :value="1"></el-option>
                <el-option label="贝体" :value="2"></el-option>
                <el-option label="高考" :value="4"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="客户来源" prop="source" label-width="100px">
              <el-select v-model="addFrom.source" placeholder="请选择客户来源">
                <el-option v-for="(item) in sourceList" :label="item.dictLabel" :value="item.dictValue" :key="item.dictValue" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="客户类型" prop="custTypeId" label-width="100px">
              <el-select v-model="addFrom.custTypeId" placeholder="请选择客户类型">
                <el-option v-for="(item) in custTypeList" :label="item.dictLabel" :value="item.dictValue" :key="item.dictValue" />
              </el-select>
            </el-form-item>
          </el-col>

        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="备注" label-width="100px">
              <el-input :rows="3" type="textarea" v-model="addFrom.remark" placeholder="请输入备注" ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <template #footer>
      <div class="dialog-footer">
        <el-button @click="createDialog = false">取 消</el-button>
        <el-button type="primary" @click="create">确 定</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import {selectArea,addThread} from '@/api/partner.js'
import {selectDictByType} from '@/api/dict.js'
export default {
  name: "CreateThread",
  data() {
    return {
      createDialog:false,
      losingShowDialog:false,
      custTypeList:[],
      areaList:[],
      sourceList:[],
      addFrom:{

      },
      addRules: {
        name: [{
          required: true,
          message: '请输入线索名称！',
          trigger: 'change'
        },],
        phone: [
          {required: true,message: '请输入联系电话！',trigger: 'change'},
          {pattern: /^1[3-9]\d{9}$/, message: "请输入正确的手机号码", trigger: "blur",}],
        areaId: [{
          required: true,
          message: '请选择所属城市！',
          trigger: 'change'
        }],
        flag: [{
          required: true,
          message: '请选择城市类型！',
          trigger: 'change'
        }],
        source: [{
          required: true,
          message: '请选择所属来源！',
          trigger: 'change'
        }],
        custTypeId: [{
          required: true,
          message: '请选择客户类型！',
          trigger: 'change'
        }],
      },
    }
  },
  methods:{
    openDialog(){
      this.createDialog = true;
    },
    create(){
      this.$refs['addRules'].validate((valid) => {
        if (valid){
          addThread(this.addFrom).then(res=>{
            if(res.code == 5101){
              this.losingShow = res.data;
              this.losingShowDialog = true;
            }
            if(res.code == 200){
              this.$emit('succeed');
              this.createDialog = false;
              this.$message.success("添加成功");
              Object.keys(this.addFrom).forEach((key) => (this.addFrom[key] = ''));
            }
          })
        }
      })
    }
  },
  created() {
    selectDictByType('crmSource').then(res=>{
      if(res.code ==200){
        this.sourceList = res.data;
      }
    })
    selectDictByType('custType').then(res=>{
      if(res.code == 200){
        this.custTypeList = res.data;
      }
    })
    selectArea().then(res=>{
      this.areaList = res.data;
    })
  }
}
</script>

<style scoped>

</style>