<template>
  <el-dialog :title="(transferType == true ? '待分配':'待转交')+List.length+'条'" v-model="transferDialog" width="1300px" draggable>
    <el-dialog
        v-model="coachDialog"
        width="500"
        :title="transferType == true ? '选择分配老师':'选择转交老师'"
        append-to-body
    >
      <el-select
          v-model="coachId"
          filterable
          remote
          reserve-keyword
          placeholder="请输入姓名"
          :remote-method="queryCoach"
          :loading="coachLoading"
          style="width: 240px"
      >
        <el-option v-for="item in coachList" :key="item.coachid" :label="item.coachid+' - '+item.coachname" :value="item.coachid"/>
      </el-select>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="transferDialog = false">取 消</el-button>
          <el-button type="primary" @click="confirm">确 定</el-button>
        </div>
      </template>
    </el-dialog>
    <el-table :data="List" height="450px">
      <el-table-column
          show-overflow-tooltip="true"
          prop="id"
          label="ID"
          width="100"
          sortable
      />
      <el-table-column
          show-overflow-tooltip="true"
          prop="name"
          label="姓名"
      />
      <el-table-column
          show-overflow-tooltip="true"
          prop="phone"
          label="电话"
      />
      <el-table-column
          show-overflow-tooltip="true"
          sortable
          label="城市"
      >
        <template #default="scope">
          <span>{{ scope.row.areaName }}-{{ scope.row.flagName }}</span>
        </template>
      </el-table-column>
      <el-table-column
          show-overflow-tooltip="true"
          prop="custTypeName"
          sortable
          label="意向度"
      />
      <el-table-column
          prop="deptName"
          sortable
          label="部门"
      />
      <el-table-column
          show-overflow-tooltip="true"
          prop="followUpName"
          sortable
          label="跟进老师"
          width="120"
      />
      <el-table-column
          show-overflow-tooltip="true"
          prop="sourceName"
          sortable
          label="来源"
      />
      <el-table-column
          show-overflow-tooltip="true"
          prop="stateName"
          sortable
          label="状态"
      />
      <el-table-column
          show-overflow-tooltip="true"
          prop="createTime"
          label="创建时间"
          sortable
          width="160"
      />
      <el-table-column
          show-overflow-tooltip="true"
          prop="createName"
          sortable
          label="创建老师"
          width="120"
      />
    </el-table>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="transferDialog = false">取 消</el-button>
        <el-button type="primary" @click="coachDialog = true">确 定</el-button>
      </div>
    </template>
  </el-dialog>

</template>

<script>

import {allocationGetCoach,transferGetCoach,allocationOrTransfer} from '@/api/partner.js'
export default {
  name: "transferData",
  data() {
    return {
      List:[],
      coachDialog:false,
      transferDialog:false,
      transferType:null,
      coachList:[],
      coachId:null,
      coachLoading:false
    }
  },
  methods: {
    openDialog(list,transferType){
      this.List = list;
      this.transferType =transferType;
      this.transferDialog = true;
    },
    queryCoach(val){
      if (this.transferType){
        this.allocationGetCoach(val);
      }else {
        this.transferGetCoach(val);
      }
    },
    confirm(){
      let item = {
        coachId:this.coachId,
        customersList:this.List,
        type:this.transferType == true ? 1:2,
      }
      allocationOrTransfer(item).then(val=>{
        if(val.code ==200){
          this.$emit('succeed');
          this.coachDialog = false;
          this.transferDialog = false;
          this.$message.success("操作成功");
        }else {
          this.$message.error(val.message);
        }
      })
    },
    allocationGetCoach(coachName){
      this.coachLoading = true;
      allocationGetCoach(coachName).then(val=>{
        this.coachLoading = false;
        if(val.code ==200){
          this.coachList = val.data;
        }
      })
    },
    transferGetCoach(coachName){
      this.coachLoading = true;
      transferGetCoach(coachName).then(val=>{
        this.coachLoading = false;
        if(val.code ==200){
          this.coachList = val.data;
        }
      })
    }
  }
}
</script>

<style scoped>

</style>